type Env = "local" | "homologation" | "iso" | "production";
type EnvTag = "%#environment#%";
type EnvConfiguration = Env | EnvTag;

export interface AppConfiguration {
  env: EnvConfiguration;
  sgConnectConfig: SgConnectConfiguration;
  widgetConfiguration: SGWTWidgetConfiguration;
  apiUrl: string;
  apiUrlAuth: string;
}

export interface SGWTWidgetConfiguration {
  environment: string;
  sharedCssUrls: string[];
}

export interface SgConnectConfiguration {
  authorizationEndpoint: string;
  clientId: string;
}

const LOCAL_CONFIGURATION = {
  env: process.env.REACT_APP_ENV,
  sgConnectConfig: {
    authorizationEndpoint: process.env.REACT_APP_AUTHORIZATION_ENDPOINT,
    clientId: process.env.REACT_APP_CLIENT_ID,
  },
  widgetConfiguration: {
    environment: process.env.REACT_APP_ENV,
  },
  apiUrl: process.env.REACT_APP_API_URL,
};

export const AppConfiguration: AppConfiguration =
  process.env.REACT_APP_USE_EXTERNAL_CONFIG === "true" ? window["globalConfiguration"] : LOCAL_CONFIGURATION;
