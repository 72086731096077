import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "app/store/store.states";
import { SgHelpCenter, Props } from "common/components/SgHelpCenter/SgHelpCenter";
import { getEnvironment } from "app/config/environment";

type OwnProps = {
  children: React.ReactNode;
};

const mapStateToProps = (state: RootState, ownProps: OwnProps): Props => ({
  environment: getEnvironment(),
  userLogin: state.auth.user.email,
  applicationId: process.env.REACT_APP_ID,
  defaultRecipient: process.env.REACT_APP_CONTACT_MAIL,
  children: ownProps.children,
});

export const SgHelpCenterContainer = connect<Props, any, OwnProps, RootState>(mapStateToProps)(SgHelpCenter);
