import { get } from "lodash";
import { ToastMessage } from "./api.typing";

export const getErrorMessage = async (toastMessage?: ToastMessage) => {
  return get(toastMessage, "friendlyErrorMessage");
};

export const sgLambdaStreamToObject = <T extends any = any>(sgLambdaStream: string): T[] => {
  const data = sgLambdaStream.split("\n").map((i) => i.split(","));
  const headers = data.shift() ?? [];
  return data.map<T>((d) => {
    const obj: any = {};
    headers.forEach((h, i) => (obj[headers[i].replace("\r", "")] = d[i].trim().replace(/\r/g, "").replace(/"/g, "")));
    return obj;
  });
};

export const ObjectToSgLambdaStream = <T extends object = object>(objectValues: T[]): string => {
  let content = "";
  objectValues.forEach((obj, index) => {
    if(index === 0) {
      const keys: (string|number|boolean)[] = Object.keys(obj);
      content += keys.join(",") + "\n";
    }
    const values: (string|number|boolean)[] = Object.values(obj);
    let stream: string = values.join(",") + (index !== objectValues.length-1 ? "\n" : "");
    content += stream;
  })
  return content;
};