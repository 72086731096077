import { Environment } from "app/config/environment";
import * as React from "react";

export interface Props {
  environment?: Environment;
  currentLanguage?: string;
  userLogin: string;
  applicationId: string;
  defaultRecipient: string;
}

export const SgHeader: React.FC<Props> = (props: Props) => {
  const id = "clm-account-center";
  const [accountCenter, setAccountCenter] = React.useState(null);

  React.useEffect(() => {
    const settingsDropDown = document.getElementsByClassName("card sgwt-personalisation");
    if (settingsDropDown && settingsDropDown.length) {
      settingsDropDown[0].remove();
    }

    const account = document.getElementById(id);
    if (account) {
      setAccountCenter(account);
    }
  }, []);

  React.useEffect(() => {
    if (!accountCenter) {
      return;
    }
    return accountCenter;
  }, [accountCenter]);

  const env = props.environment && props.environment !== "production" ? props.environment : null;
  return (
    <>
      <sgwt-account-center
        id="my-account-center"
        environment={env}
        mode="sg-markets"
        language={props.currentLanguage}
        authentication="sg-connect-v2"
      />
    </>
  );
};
