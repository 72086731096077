import React from "react";

export interface AppGlobalContext {
  language?: string;
}

export interface GlobalContextProps {
  globalContext: AppGlobalContext;
}

export const defaultContext: AppGlobalContext = {
  // language: 'en'
};

export const GlobalContext = React.createContext<AppGlobalContext>(defaultContext);

export function getDisplayName<T = {}>(WrappedComponent: React.ComponentType<T>) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

// HOC to provide the globalContext to the wrapped component.
// Since TypeScript 3.2, there is an issue with HOC.
// It may be due to a bug in TypeScript itself... So using `any` "solves" the issue...
// cf. https://stackoverflow.com/questions/53556160/react-hoc-and-typescript-3-2
export const withGlobalContext = <P extends GlobalContextProps>(WrappedComponent: React.ComponentType<P>) => {
  const wrapper: React.FC<Pick<P, Exclude<keyof P, keyof GlobalContextProps>>> = (props) => (
    <GlobalContext.Consumer>{(context) => <WrappedComponent {...(props as P)} {...context} />}</GlobalContext.Consumer>
  );
  wrapper.displayName = `WithGlobalContext(${getDisplayName(WrappedComponent)})`;
  return wrapper;
};
