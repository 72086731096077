/* eslint-disable react-hooks/exhaustive-deps */
import { RootState } from "app/store/store.states";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { DefaultBoardConfig, UserBoardConfig } from "datas/models";
import { ProgressBar } from "react-bootstrap";

const mapStateToProps = (state: RootState) => ({
  app: {...state.app},
  board: {...state.modules.board},
  datas: {...state.datas}
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
  currentBoard: UserBoardConfig | DefaultBoardConfig;
};

type State = {
  iFrameWidth: number;
  isLoading: boolean;
  isLoaded: boolean;
  hasBeenOpen: boolean;
  progressBar: number;
}

export const BoardScreen: React.FC<Props> = (props: Props) => {

  const [state, setState] = React.useState<State>({
    iFrameWidth: 100,
    isLoading: true,
    isLoaded: false,
    hasBeenOpen: false,
    progressBar: 0,
  });

  React.useEffect(() => {
    if(state.hasBeenOpen === false && props.currentBoard?.sgBoardId === getCurrentBoardId()) {
      onLoadStart()
      console.log("start loard")
    }
    if(state.isLoaded && props.currentBoard?.sgBoardId === getCurrentBoardId()) {
      setState({...state, iFrameWidth: 99.9});
      setTimeout(() => setState({...state, iFrameWidth: 100}), 100);
      console.log("set iframe size")
    }
  }, [props.app.currentBoard, state.isLoaded])

  React.useEffect(() => {
    if( state.hasBeenOpen === true && state.isLoading && state.isLoaded === false) {
      if(state.progressBar<100) {
        setTimeout(() => setState({...state, progressBar: state.progressBar + 1, isLoading: true}), 40);
      }
      else {
        setTimeout(() => onLoadEnd(), 10);
        console.log("END PROGRESS BAR")
      }
    }
  }, [state.progressBar, state.isLoading, props.app.currentBoard, state.hasBeenOpen, state.isLoaded])

  const getCurrentBoardId = (): string | undefined => {
    if(props.app.currentBoard) {
      return props.app.currentBoard?.sgBoardId;
    }
    else {
      return props.datas.defaultBoardConfigs.find(x => x)?.sgBoardId;
    }
  }

  const onLoadEnd = (): void => setState({...state, isLoading: false, isLoaded: true, progressBar: 100})
  const onLoadStart = (): void => setState({...state, isLoading: true, progressBar: 0, hasBeenOpen: true})

  if(state.hasBeenOpen === false) return <></>

  return (
    <>
      {state.isLoading && props.currentBoard?.sgBoardId === getCurrentBoardId()?
        <>
          <ProgressBar variant="dark" now={state.progressBar}  min={0} max={80} style={{height: 30, marginTop: 50, margin: 50, marginBottom: 20}} label={<p className="mr-3 mt-3">{state.progressBar + "%"}</p>}/>
          <span style={{height: 30, marginTop: 0, marginLeft: 60}}>Loading...</span>
        </>
      : null}

      <div className={`w-${state.iFrameWidth} h-100 ` + (state.isLoaded && props.currentBoard?.sgBoardId === getCurrentBoardId() ? "" : "d-none")}
      style={{width: state.iFrameWidth + "%!important"}}>
        <iframe
          title={props.currentBoard.name}
          src={`https://dashboard.sgmarkets.com/explore/boards/${props.currentBoard.sgBoardId}/preview?fullscreen=true` + (props.currentBoard.sgBoardConfigId ? "&configurationId=" + props.currentBoard.sgBoardConfigId : "")}
          //src={"https://dashboard.sgmarkets.com/explore/boards?owned=true&createdByMe=true&official=false&page=0&size=100"}
          //hidden={!state.isLoaded || props.currentBoard?.sgBoardId !== getCurrentBoardId()}
          className={`w-${state.iFrameWidth} border-0 `}

          allowFullScreen
          width={state.iFrameWidth + "%"}
          height="90%"
          //onLoad={onLoadEnd}
        ></iframe>
      </div>
    </>
  )
};

export default connect(mapStateToProps, mapDispatchToProps)(BoardScreen);
