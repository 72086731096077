import { ModulesState } from "app/store/store.states";
import { combineReducers } from "redux";
import { boardReducers } from "modules/Board/store/board.reducer";
import { homeReducers } from "modules/Home/store/home.reducer";

export const modulesReducers = combineReducers<ModulesState>({
  board: boardReducers,
  home: homeReducers,
});

export default modulesReducers;