import React, { Fragment, useEffect, useState } from "react";
import { IntlProvider as ReactIntlProvider } from "react-intl";
import en from "./en.json";
import fr from "./fr.json";
import { getWidgetBus } from "common/utils/api/api.helpers";

export const BUS_TOPIC_GLOBALLANGUAGE = "global.language";
export const BUS_TOPIC_SGCONNECT_ACCESSTOKEN = "sg-connect.access-token";
export const BUS_TOPIC_SGCONNECT_USERCONNECTION = "sg-connect.user-connection";
export const BUS_TOPIC_SGCONNECT_USERINFO = "sg-connect.user-info";
export const BUS_TOPIC_SGCONNECT_GRANTEDSCOPE = "sg-connect.granted-scope";

type SupportedLocale = "en" | "fr";
export const locales: { [key in SupportedLocale]: { [key: string]: string } } = {
  en,
  fr,
};

export const IntlProvider: React.FC = ({ children }) => {
  const [locale, setLocale] = useState<SupportedLocale>("en");

  // Use the SGWT Widgets bus to be aware of the modification of the language,
  // which is triggered by the <sgwt-account-center> widget.
  useEffect(() => {
    const bus = getWidgetBus();

    if (bus) {
      const languageSubscription = bus.subscribe<string>(BUS_TOPIC_GLOBALLANGUAGE, (lang) => {
        if (lang && Object.keys(locales).indexOf(lang.toLowerCase()) >= 0) {
          setLocale(lang.toLowerCase() as SupportedLocale);
        }
      });
      return () => {
        bus.unsubscribe(languageSubscription);
      };
    }
    return () => {};
  }, []);

  return (
    <ReactIntlProvider locale={locale} messages={locales[locale]} textComponent={Fragment}>
      {children}
    </ReactIntlProvider>
  );
};
