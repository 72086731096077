import { applyMiddleware, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import { rootReducer } from "app/store/root.reducer";
import { loggerMiddleware } from "app/store/logger.middleware";
import { apiMiddleware } from "app/store/api.middleware";
import { environment } from "app/config/environment";
import { History, createBrowserHistory } from "history";

/**
 * this method initialize the store and the navigation history of your application
 * we inject in the store configuration all the middlewares of the application
 *  historyMiddleWare: created be the connected-react-router
 *  apiMiddleware: our custom middleware to feed http request headers
 *  loggerMiddleware: our custom middleware to log every action
 */
export const initialize = () => {
  const history = createBrowserHistory();
  const store = configureStore(history);
  return { store, history };
};

export const configureStore = (history: History) => {
  const store = createStore(
    rootReducer(history),
    configureMiddleWare([routerMiddleware(history), apiMiddleware, loggerMiddleware])
  );
  //epicMiddleware.run(rootEpic);
  return store;
};

export const configureMiddleWare = middleWares => {
  return environment !== "production" ? configureDevMiddleWare(middleWares) : applyMiddleware(...middleWares);
};

const configureDevMiddleWare = middleware => {
  const logger = createLogger({ collapsed: true });
  return composeWithDevTools(applyMiddleware(...middleware, logger));
};
