import { Environment as LoggerEnvironment } from "@dcc-cli/ic-logger/dist/types";
import { AppConfiguration } from "./configuration";

export type Environment = "test" | "development" | "homologation" | "iso" | "production";
export const environment = process.env.NODE_ENV as Environment;

export const getEnvironment = (): Environment => {
  const executionMode = process.env.NODE_ENV;
  if (executionMode !== "production") {
    return executionMode as Environment;
  }
  return AppConfiguration.env as Environment;
};

const mapEnvToIcLoggerEnv = (env: Environment): LoggerEnvironment => {
  switch (env) {
    case "homologation":
      return "HOM";
    case "iso":
      return "ISO";
    case "production":
      return "PRD";
    default:
      return "HOM";
  }
};

export const getLoggerEnvironment = (): LoggerEnvironment => mapEnvToIcLoggerEnv(getEnvironment());
