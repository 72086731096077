import * as React from "react";
import { ErrorInfo } from "react";
import { logError } from "modules/Authentication/utils/logger";
import { environment } from "app/config/environment";
import ServerErrorPage from "common/components/ErrorPage/ServerErrorPage";
import ErrorPage from "../ErrorPage/ErrorPage";

interface State {
  hasError: boolean;
  errorMessage?: string;
}

type Props = React.PropsWithChildren<{}>;

export class ErrorHandler extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, errorMessage: undefined };
  }

  render() {
    if (this.state.hasError) {
      if(environment !== "production") {
        return (<ErrorPage code={500} descriptionLabel={`Error detail: ${this.state.errorMessage}`} fullscreen={true} />);
      }
      else {
        return (<ServerErrorPage />)
      }
    }
    
    return <>{this.props.children}</>;
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    logError(error, errorInfo ? errorInfo.componentStack : undefined);
    this.setState({ hasError: true, errorMessage: error ? error.message : " " });
  }
}
