import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "common/constants/routes.const";
import NotFoundPage from "common/components/ErrorPage/NotFoundPage";
import { SgHelpCenterContainer } from "common/components/SgHelpCenter/sgHelpCenter.container";
import MainScreen from "app/components/MainScreen";
import AuthProvider from "modules/Authentication/components/AuthProvider";
import { IntlProvider } from "common/ressources/IntlContext";
import { GlobalContext } from "common/ressources/GlobalContext";

const AppRouter: React.FC = () => {
  return (
    <AuthProvider>
      <SgHelpCenterContainer>
        <IntlProvider>
          <GlobalContext.Provider value={{}}>
            <React.StrictMode>
              <Switch>
                <Route exact path="/" component={MainScreen} />
                <Route exact={true} path={ROUTES.NOT_FOUND} component={NotFoundPage} />
                <Route exact={true} component={NotFoundPage} />
              </Switch>
            </React.StrictMode>
          </GlobalContext.Provider>
        </IntlProvider>
      </SgHelpCenterContainer>
    </AuthProvider>
  );
};

export default AppRouter;
