import classNames from "classnames";
import * as React from "react";
import styles from "./Loading.scss";
import { Spinner } from "./Spinner";

export interface Props {
  isVisible?: boolean;
  message?: string;
}

export const Loading: React.FunctionComponent<Props> = ({ isVisible = true, message = "Loading..." }: Props) =>
  isVisible ? (
    <div className="spinner-grow spinner-grow-sm" role="status">
      {message}
    </div>
  ) : null;

  export const LoadingRound: React.FunctionComponent<Props> = ({ isVisible = true, message = "Loading..." }: Props) =>
  isVisible ? (
    <div className={classNames("no-padding", styles?.featureLoader)}>
      <span className={classNames(styles?.loadingContainer)}>
        <Spinner />
        {message}
      </span>
    </div>
  ) : null;