import * as React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Table, Modal } from "react-bootstrap";
import { RootState } from "app/store/store.states";
import datasSlice from "datas/store/datas.slice";
import * as _Services from  "../../../datas/services"
import * as _Models from  "../../../datas/models"
import * as _Dtos from  "../../../datas/dtos"
import homeSlice from "../store/home.slice";
import UserBoardsSearch from "./UserBoardsSearch";
import UserBoardItem from "./UserBoardItem";
import { SGBoardToUserBoard } from "datas/utils/SgBoards";

const mapStateToProps = (state: RootState) => ({
  datas: {...state.datas},
  home: {...state.modules.home},
  auth: {...state.auth}
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setUserBoards: (boards: _Models.UserBoardConfig[]) => dispatch(datasSlice.actions.updateAll({type: "userBoardConfigs", datas: boards})),
  toogleUserBoardsModal: () => dispatch(homeSlice.actions.toogleUserBoardsModal())
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};
type State = {
  allUserSgBoards?: _Dtos.SgBoardsDTO;
  selectedUserBoardsState: Map<string, _Models.UserBoardConfig>;
}

export const UserBoardsModal: React.FC<Props> = (props: Props) => {

  const initSelectedUserBoardsState = () =>
  new Map<string, _Models.UserBoardConfig>(
    props.datas.userBoardConfigs.map(b => [
        b.sgBoardId,
        b,
      ])
  );

  const [state, setState] = React.useState<State>({
    allUserSgBoards: undefined,
    selectedUserBoardsState: initSelectedUserBoardsState()
  })

  React.useEffect(() => {
    _Services
    .getSgBoards(props.home.searchUserBoardsValue)
    .then(dtos => {
      setState({...state, allUserSgBoards: dtos});
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.home.searchUserBoardsValue]);

  const onSelectSgBoard = (sgBoard: _Dtos.SgBoardContentDTO, icon: string, isSelected: boolean) => {
    if(isSelected) {
      setState({...state, selectedUserBoardsState: new Map(state.selectedUserBoardsState.set(
        sgBoard.id.toString(),
        SGBoardToUserBoard(
          sgBoard,
          props.auth.user.email,
          props.auth.user.id,
          icon)
      ))})
    }
    else {
      const newBoardsState = new Map(state.selectedUserBoardsState);
      newBoardsState.delete(sgBoard.id.toString());
      setState({...state, selectedUserBoardsState: newBoardsState});
    }
  }

  const onUpdateUserBoards = () => {
    _Services.replaceUserBoards([...state.selectedUserBoardsState.values()], props.auth.user)
    .then(datas => props.toogleUserBoardsModal())
    .then(datas => _Services.getUserBoards(props.auth.user).then(d => props.setUserBoards(d)))
  }

  return (
    <>
      <Modal
        centered
        fade={true}
        show={props.home.isOpenPersonalBoardsModal}
        size="xl"
        onHide={props.toogleUserBoardsModal}
        //onClosed={props.toogleUserBoardsModal}
      >
        <div className="modal-header mb-0 pb-0">
          <div className="pull-left">
          </div>
          <h3 className="modal-title justify-content-start">Select your personal boards</h3>
          <div className="pull-right">
            <button
              onClick={props.toogleUserBoardsModal}
              type="button"
              className="close icon"
              data-dismiss="modal"
              aria-label="Close"
            >
              close
            </button>
          </div>
        </div>

        {state.allUserSgBoards && state.allUserSgBoards.content.length === 0 && !props.home.searchUserBoardsValue
        ? <Modal.Body className="pb-0 mt-5 d-flex justify-content-center text-center text-warning">
            <p className="modal-title">You have not created any personal board yet. Go to <a href="https://dashboard.sgmarkets.com/" target="_'blank'">SGM Dashboard <i className="icon">open_in_new</i></a> to start creating boards, then they will be proposed here.</p>
          </Modal.Body>
        : <>
            <div className="modal-header mb-3 pb-0 pt-3">
              <p className="modal-title justify-content-start">Select boards from the list of <b>your personal boards</b> created in <a href="https://dashboard.sgmarkets.com/" target="_'blank'">SGM Dashboard <i className="icon">open_in_new</i></a></p>
            </div>

            <div className="mr-4 ml-4 mb-4 mt-0" >
              <UserBoardsSearch />
            </div>

            <Modal.Body>
              <Table className="striped bordered hover">
                <tbody>
                  {state.allUserSgBoards
                  ? state.allUserSgBoards?.content.map(sgBoard => (
                    <UserBoardItem
                    sgBoard={sgBoard}
                    onSelect={onSelectSgBoard}
                    isSelected={state.selectedUserBoardsState.has(sgBoard.id.toString())}
                    icon={state.selectedUserBoardsState.get(sgBoard.id.toString())?.icon || ""}
                    />
                  ))
                  : null}
                </tbody>
              </Table>
            </Modal.Body>
          </>
        }

        <Modal.Footer>
          {state.allUserSgBoards && state.allUserSgBoards.content.length === 0 && !props.home.searchUserBoardsValue
          ?  <>
              <button
                type="button"
                className="btn btn-lg btn-flat-secondary"
                data-dismiss="modal"
                onClick={props.toogleUserBoardsModal}
              >
                Close
              </button>
            </>
          :  <>
            <button
              type="button"
              className="btn btn-lg btn-flat-secondary"
              data-dismiss="modal"
              onClick={props.toogleUserBoardsModal}
            >
              Cancel
            </button>
            <button
            type="button"
            className="btn btn-lg btn-primary "
            data-dismiss="modal"
            data-testid="Submit"
            onClick={onUpdateUserBoards}>
              Save
            </button>
          </>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(UserBoardsModal);
