import { getAuthorizationHeader } from "./api.helpers";
import _, { get } from "lodash";
import { stringifyParams } from "../stringify";
import { toastifyError, toastifySuccess } from "../toasterNotifyer";
import { getErrorMessage } from "./api.helper";
import { ApiRequest, FetchConfig, FetchError, FetchRetry, HttpRequestHeader, ToastMessage } from "./api.typing";
import { getApiServiceUrl } from "app/config/getApiServiceUrl";

const DefaultHeader = { "Content-Type": "application/json", "Accept": "*/*" };

export const fetchRetry: FetchRetry = (method: "GET" | "POST", credentials: "omit" | "include" = "include") => (
  url: string,
  params?: string | object,
  body?: unknown,
  headers: HttpRequestHeader = getAuthenticationHeader(),
  config: FetchConfig = {delay: 2000, reTries: 1}
): Promise<Response> => {

  const defaultHeaders = body ? { Accept: "application/json", "content-type": "application/json" } : { Accept: "application/json" };

  const finalHeaders = { ...defaultHeaders, ...headers };
  const requestInit = {
    body: JSON.stringify(body),
    credentials,
    headers: finalHeaders,
    method,
  } as RequestInit;

  let urlQuery = url;
  if (params) {
    if (typeof params === "string") {
      urlQuery += params;
    } else {
      urlQuery = query(urlQuery, params);
    }
  }

  const wait = (delay: number) => {
    return new Promise((resolve) => setTimeout(resolve, delay));
  }

  let fetchErrorResponse: Response | undefined = undefined;
  const fetchErrorRequest: Partial<Request> = new Request(url, requestInit);

  return fetch(urlQuery, requestInit)
    .then((response: Response) => {
      if (
        config.onValidateResponse
          ? config.onValidateResponse(response)
          : response === undefined || response?.status !== 200 || response?.ok !== true
      ) {
        fetchErrorResponse = response;
        return Promise.reject(
          config.onGetError ? config.onGetError(response, fetchErrorRequest) : "Fetch (and retry) API error " + response.status
        );
      }
      return Promise.resolve(response);
    })
    .catch(
      (error): Promise<Response> => {
        if (config.reTries <= 0) {
          return Promise.reject(catchFetchError(fetchErrorRequest, fetchErrorResponse)(error));
        }
        return wait(config.delay).then(() =>
          fetchRetry(method, credentials)(url, params, body, headers, { ...config, reTries: config.reTries - 1 })
        );
      }
    );
}

export const flattenObjectToQueryParams = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: { [key: string]: any },
  prefix = "",
  filterDefaultValues = false
): string => {
  return _.flatMap(_.keys(params), (name: string) => {
    return typeof params[name] !== "object"
      ? `${prefix}${name}=${encodeURIComponent(params[name])}`
      : params[name] instanceof Array
      ? _.castArray(params[name])
          .map((v) => `${prefix}${name}=${encodeURIComponent(v)}`)
          .join("&")
      : flattenObjectToQueryParams(params[name], `${prefix}${name}.`);
  })
    .filter((param) => param.split("=")[1] || !filterDefaultValues)
    .filter((param) => param.length > 0)
    .join("&");
}

export const query = (
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: { [key: string]: any },
  filterDefaultValues = false
): string => {
  return `${url}?${flattenObjectToQueryParams(
    params,
    "",
    filterDefaultValues
  )}`;
}

export const catchFetchError = <T>(request?: Partial<Request>, response?: Partial<Response>) => (error: any): Promise<T> => {
  const fetchError: FetchError = {
    error,
    request,
    response,
  };
  console.log(fetchError);
  return Promise.reject(fetchError);
}

function createRequest(request: ApiRequest, includeGetDefaultHeader = true) {
  const { url, params, method, headers, body } = request;
  const requestUrl = `${url}${stringifyParams(params)}`;
  return fetch(
    requestUrl, 
    { 
      method, 
      headers: { ...(includeGetDefaultHeader ? (body ? { Accept: "application/json", "content-type": "application/json" } : { Accept: "application/json" }) : {}), ...headers }, 
      body: body ? JSON.stringify(body): undefined 
    }
  );
}

async function tryCatch(
  f: () => Promise<Response>,
  toastMessage?: ToastMessage,
  needToastifySuccess = false,
  returnAsJson = true
): Promise<any> {
  try {
    const result = await f();
    if (!result.ok) {
      if (result.status === 404) {
        toastifySuccess(toastMessage?.ressourceNotFoundMessage ?? "");
      } else {
        toastifyError(await getErrorMessage(toastMessage));
      }
      return result;
    }
    if (needToastifySuccess) {
      toastifySuccess(toastMessage?.successMessage ?? "");
    }
    if (returnAsJson) {
      return await result.json();
    }
    return result;
  } catch (error) {
    toastifyError(get(toastMessage, "friendlyErrorMessage") || error);
    return null;
  }
}

export const createRepository = () => ({
  getFromAPI: <T>(endpoint: string, headers: HttpRequestHeader = getAuthenticationHeader(), params?: object, body?: object, toastMessage?: ToastMessage): Promise<T> => {
    const getFetch = () => createRequest({ method: "GET", headers, url: getApiServiceUrl() + endpoint, params: params, body: body }, true);
    return tryCatch(getFetch, toastMessage);
  },
  getStreamFromAPI: (
    endpoint: string,
    headers: HttpRequestHeader = getAuthenticationHeader(),
    params?: object,
    body?: object,
    toastMessage?: ToastMessage
  ): Promise<Response> => {
    const getStreamFetch = () => createRequest({ method: "GET", headers, url: getApiServiceUrl() + endpoint, params, body }, true);
    return tryCatch(getStreamFetch, toastMessage, false, false);
  },
  postStreamFromAPI: (
    endpoint: string,
    headers: HttpRequestHeader = getAuthenticationHeader(),
    params?: object,
    body?: object,
    toastMessage?: ToastMessage
  ): Promise<Response> => {
    const getStreamFetch = () => createRequest({ method: "POST", headers, url: getApiServiceUrl() + endpoint, params, body }, true);
    return tryCatch(getStreamFetch, toastMessage, false, false);
  },
  getStreamFromSG: (
    url: string,
    headers: HttpRequestHeader = getAuthenticationHeader(),
    params?: object,
    body?: object,
    toastMessage?: ToastMessage
  ): Promise<Response> => {
    const getStreamFetch = () => createRequest({ method: "GET", headers, url: url, params, body }, true);
    return tryCatch(getStreamFetch, toastMessage, false, false);
  },
  getFromSG: <T>(url: string, params?: object, body?: object, headers: HttpRequestHeader = getAuthenticationHeader(), toastMessage?: ToastMessage): Promise<T> => {
    const getFetch = () => createRequest({ method: "GET", headers: getAuthenticationHeader(), url: url, params: params, body: body }, true);
    return tryCatch(getFetch, toastMessage);
  },
});

export const getHeader = (): HttpRequestHeader => ({
  ...getAuthenticationHeader(),
  ...DefaultHeader
})

export const getAuthenticationHeader = (): HttpRequestHeader => ({
  authorization: getAuthorizationHeader() ?? "",
});
