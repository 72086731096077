import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { HomeState } from './home.states';

const initialState: HomeState = {
  isOpenPersonalBoardsModal: false
}

export const homeSlice = createSlice({
  name: 'home',
  initialState: initialState,
  reducers: {
    toogleUserBoardsModal: (state) => {
      state.isOpenPersonalBoardsModal = !state.isOpenPersonalBoardsModal;
    },
    setSearchUserBoardValue: (state, action: PayloadAction<string | undefined>) => {
      state.searchUserBoardsValue = action.payload;
    },
  },
})

export default homeSlice;