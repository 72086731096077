import { Environment } from "app/config/environment";
import * as React from "react";

export type Props = React.PropsWithChildren<CompProps>;

type CompProps = {
  environment: Environment;
  userLogin: string;
  defaultRecipient: string;
  applicationId: string;
};

export class SgHelpCenter extends React.Component<Props> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: Props) {
    super(props);
  }

  public componentDidUpdate(prevProps: Props) {
    const element = document.getElementById("my-help-center");
    if (element && this.props.applicationId !== prevProps.applicationId) {
      try {
        (element as any).setApplicationId(this.props.applicationId);
      } catch (e) {
        console.log(e);
      }
    }
  }

  public render() {
    const { userLogin, defaultRecipient, applicationId, children } = this.props;
    return (
      <>
        {children}
        {applicationId && (
          <aside style={{ zIndex: 1065 }}>
            <sgwt-help-center
              id="my-help-center"
              introduction-tour="true"
              sg-connect-support="sg-connect-v2"
              user={JSON.stringify(userLogin)}
              default-send-to={defaultRecipient}
              application-id={applicationId}
            />
          </aside>
        )}
      </>
    );
  }
}
