import { HttpResquestHeader } from "common/types/api";
import { Meta } from "typescript-fsa";

export interface BaseActionLog {
  description: string;
  event: string;
}

export interface ActionLog extends BaseActionLog {
  level?: LogLevels;
  logType: LogTypes;
}

export type RouterActionLog = BaseActionLog;

export type AsyncActionLog = BaseActionLog;

export type ActionLogMeta = AsyncActionLog | ActionLog | RouterActionLog;

export interface ActionMeta extends Meta {
  logger?: ActionLogMeta;
  headers?: HttpResquestHeader;
}

export enum LogTypes {
  functional = "functional",
  technical = "technical",
  performance = "technical",
  feature = "feature",
}

export enum LogLevels {
  debug = "debug",
  info = "info",
  warn = "warn",
  error = "error",
  fatal = "fatal",
}

export enum AsyncActionTypes {
  started = "STARTED",
  done = "DONE",
  failed = "FAILED",
}

export interface LogMessage {
  name: string;
  type: LogTypes;
  feature: string;
  event: string;
  description: string;
  level?: LogLevels;
  watchKeyOrDuration?: number | string;
}
