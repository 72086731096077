import { createRepository } from "common/utils/api/api";
import { SgBoardsDTO } from "datas/dtos/SgDashboardBoards.dto";

export const getSgBoards = (search?: string): Promise<SgBoardsDTO> => {
  return createRepository()
  .getFromSG<SgBoardsDTO>(
    "https://sg-dashboard.fr.world.socgen/api/v3/boards",
    {
      owned: true,
      official: false,
      page: 0,
      size: 100,
      sort: "popularity,desc",
      q: search
    }
  );
};

export default {
  getUsgetSgDashboardBoardsBoards: getSgBoards
};
