import { ComponentColor } from "common/types/utils";
import { upperFirst } from "lodash";
import * as React from "react";
import { toast, Id } from "react-toastify";
import ToastContent from "./ToastContent";

export type ToastMessage = string | JSX.Element;
const genericErrorMessage = "An error occured. Contact the support if the problem persists";

const toastOptions = (className: string) => ({
  closeButton: false,
  className: `toast fade show ${className}`,
});

export const toastify = (message: ToastMessage, color: ComponentColor, title: string = undefined, detail?: string) => {
  if (message || title) {
    toast(
      <ToastContent title={title || upperFirst(color)} message={message} detail={detail} />,
      toastOptions(`toast-${color}`)
    );
  }
};
export const toastifySuccess = (message: ToastMessage, title?: string) => toastify(message, "success", title);
export const toastifyErrorMessage = (message: ToastMessage, title?: string) =>
  toastify(message, "danger", title ?? "Error");
export const toastifyWarningMessage = (message: ToastMessage, title?: string, detail?: string) =>
  toastify(message, "warning", title, detail);
export const toastifyInfo = (message: ToastMessage) => toastify(message, "info");
export const toastifyError = (errorMessage?: any, message = genericErrorMessage): Id => {
  const error = errorMessage ?? errorMessage?.message ?? message;
  return toast.error(`Error while calling API : ${error}`, {
    autoClose: false,
  });
};
