import { EventEmitter } from "events";
import * as React from "react";
import ErrorPage from "./ErrorPage";

const NotFoundPage: React.FC = () => {
  const event = new EventEmitter();

  event.addListener("actionClicked", () => {
    window.history.back();
  });

  return (
    <ErrorPage
      code={404}
      titleLabel="Page not found."
      descriptionLabel="Sorry, we couldn't find this page."
      actionButtonLabel="Go back"
      actionButtonLink={window.location.href + "#"}
      actionButtonClicked={event}
      fullscreen={true}
    />
  );
};

export default NotFoundPage;
