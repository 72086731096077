import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { DatasState } from './datas.states';
import TypeHelper from "../../common/types/utils.types";

const initialState: DatasState = {
  userBoardConfigs: [],
  defaultBoardConfigs: [],
  teamConfigs: [],
}

const PRIMARY_KEYS = {
  teamConfigs: ["id"],
  defaultBoardConfigs: ["configId", "boardId"],
  userBoardConfigs: ["userId", "boardId"]
}

export const datasSlice = createSlice({
  name: 'datas',
  initialState: initialState,
  reducers: {
    updateAll: <D extends keyof DatasState = keyof DatasState>(state, action: PayloadAction<{type: keyof DatasState; datas: DatasState[D]}>) => {
      state[action.payload.type] = action.payload.datas;
    },

    deleteAll: <D extends keyof DatasState = keyof DatasState>(state, action: PayloadAction<{type: keyof DatasState;}>) => {
      state[action.payload.type] = [];
    },

    update: <D extends keyof DatasState = keyof DatasState>(state, action: PayloadAction<{type: keyof DatasState; data: TypeHelper.ArrElement<DatasState[D]>}>) => {
      state[action.payload.type] = [...state.viewApps.filter(x => 
        !PRIMARY_KEYS[action.payload.type].every(key => {
          if(x.id === action.payload.data[key]){
            return true;
          }
          return false;
        })
      ), action.payload]
    },

    delete: <D extends keyof DatasState = keyof DatasState>(state, action: PayloadAction<{dataType: keyof DatasState; dataValue: TypeHelper.ArrElement<DatasState[D]>}>) => {
      state[action.payload.dataType] = [...state.viewApps.filter(x =>
        !PRIMARY_KEYS[action.payload.dataType].every(key => {
          if(x.id === action.payload.dataValue[key]){
            return true;
          }
          return false;
        })
      )]
    }
  },
})

export default datasSlice;