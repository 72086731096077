/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import * as _Models from  "../../../datas/models"
import { Nav } from "react-bootstrap";

type Props = {
  isActive?: boolean;
  defaultBoard: _Models.DefaultBoardConfig;
  onSelectBoard: (board: _Models.DefaultBoardConfig) => void;
};

export const DefaultBoardTab: React.FC<Props> = (props: Props) => {

  return (
    <>
      <Nav.Item
        className={`nav-item`}
        role="button"
        key={props.defaultBoard.sgBoardId}
        onClick={() => props.onSelectBoard(props.defaultBoard)}
      >
        <a className={"nav-link " + (props.isActive ? "active" : "")}
        data-toggle="tooltip" data-placement="right"
        title={props.defaultBoard.description}
        data-original-title={props.defaultBoard.description}>
          <i className="icon icon-sm pr-2">{props.defaultBoard.icon}</i>
          {props.defaultBoard.name}
        </a>
      </Nav.Item>
    </>
  );
};
export default DefaultBoardTab;
