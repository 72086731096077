import { logAction } from "modules/Authentication/utils/logger";

/**
 * this middleware is used in order to log every actions which are dispatch
 * we call next method to access of the next middleware in the global pipeline
 */
export const loggerMiddleware = () => next => action => {
  logAction(action);
  next(action);
};
