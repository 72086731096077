import { Button } from "@sgbs-ui/core";
import * as React from "react";
import { ToastMessage } from "./toasterNotifyer";

export interface ToastProps {
  title: string;
  message: ToastMessage;
  detail?: string;
}

const ToastContent: React.FC<ToastProps> = ({ title, message, detail }) => (
  <>
    <div className="toast-header">
      <strong>{title}</strong>
      <Button
        type="button"
        className="ml-2 mb-1 close pointer"
        data-dismiss="toast"
        aria-label="Close"
        icon="close"
      ></Button>
    </div>
    <div className="toast-body">
      <div className="text-primary">{message}</div>
      {detail && <div className="text-primary">{detail}</div>}
    </div>
  </>
);

export default ToastContent;
