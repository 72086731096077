import * as React from "react";
import * as ReactDOM from "react-dom";
import { App } from "app/components/App";
import { sgwtConnectComponent } from "modules/Authentication/utils/getWidgetBus";
import { initialize } from "app/store/store";
// entry point for applying SG Boostrap 4 style
import "app/styles/main.scss";

const sgwtConnect = sgwtConnectComponent();

/**
 * entry point of the application
 * we check the current user authentication:
 * if yes: we render the application
 * if no: we try to connect the current user
 */
if (sgwtConnect.isAuthorized()) {
  const { store, history } = initialize();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ReactDOM.render(<App store={store as any} history={history} />, document.getElementById("root"));
} else if (!sgwtConnect.getAuthorizationError()) {
  sgwtConnect.requestAuthorization();
}
