import React from "react";

export const FeedbackButton: React.FC = () => {
  return (
    <button
      className="btn btn-flat-primary btn-icon-text text-nowrap"
      onClick={() => {
        const helpCenter: any = document.querySelector("sgwt-help-center");
        helpCenter?.feedback();
      }}
    >
      <i className="icon">edit</i>
      Leave feedback
    </button>
  );
};
