export type TeamPaths = {
  teamPathPart1: string;
  teamPathPart2: string;
  teamPathPart3: string;
}

export const getUserTeamPaths = (userTeam?: string) => {
    const userDept = (userTeam ?? "").split("/");
    const teamPaths: TeamPaths = {
      teamPathPart1: userDept[0] ?? "",
      teamPathPart2: userDept[1] ?? "",
      teamPathPart3: userDept[2] ?? "",
    };
    return teamPaths;
}