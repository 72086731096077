import ENDPOINTS from "common/constants/endpoints.const";
import { createRepository } from "common/utils/api/api";
import { sgLambdaStreamToObject } from "common/utils/api/api.helper";
import TeamProfile from "datas/models/TeamProfile.m";
import { getUserTeamPaths } from "datas/utils/TeamPaths";

export const getAllTeamProfiles = (): Promise<TeamProfile[]> => {
  return createRepository()
  .getStreamFromAPI(ENDPOINTS.TeamConfigs)
  .then((response) => response.text())
  .then((response) => sgLambdaStreamToObject<TeamProfile>(response));
};

export const getAllUserTeamProfiles = (userTeam?: string): Promise<TeamProfile[]> => {
  return createRepository()
  .getStreamFromAPI(ENDPOINTS.TeamConfigs)
  .then((response) => response.text())
  .then((response) => sgLambdaStreamToObject<TeamProfile>(response))
  .then(datas => {
    if(userTeam) {
      const teamPaths = getUserTeamPaths(userTeam);
      const team = datas.filter(
        ({teamPathPart1, teamPathPart2, teamPathPart3}) =>
          (teamPathPart1 === teamPaths?.teamPathPart1 || teamPathPart1 === "") &&
          (teamPathPart2 === teamPaths?.teamPathPart2 || teamPathPart2 === "") &&
          (teamPathPart3 === teamPaths?.teamPathPart3 || teamPathPart3 === "")
      );
      return team;
    }
    return datas.find(t => t) ? [datas.find(t => t)] : [];
  });
};

export const getUserClosestTeamProfile = (userTeam?: string): Promise<TeamProfile | undefined> => {
  return getAllUserTeamProfiles(userTeam)
  .then(teamConfigs => 
  {
    let bestTeamConfig: (TeamProfile & {score: number;}) | undefined = undefined;

    teamConfigs.forEach(tc => {
      let currentScore = 0;
      const teamPaths = getUserTeamPaths(userTeam);
      if(teamPaths.teamPathPart1 === tc.teamPathPart1) currentScore++;
      if(teamPaths.teamPathPart2 === tc.teamPathPart2) currentScore++;
      if(teamPaths.teamPathPart3 === tc.teamPathPart3) currentScore++;

      if(bestTeamConfig === undefined || bestTeamConfig.score < currentScore) bestTeamConfig = {...tc, score: currentScore};
    })

    return bestTeamConfig;
  })
};

export default {
  getAllUserTeamProfiles,
  getAllTeamProfiles,
  getUserClosestTeamProfile
}
