import * as React from "react";
import { RootState } from "app/store/store.states";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import * as _Dtos from  "../../../datas/dtos"

const mapStateToProps = (state: RootState) => ({});
const mapDispatchToProps = (dispatch: Dispatch) => ({});
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
  sgBoard: _Dtos.SgBoardContentDTO;
  onSelect: (sgBoard: _Dtos.SgBoardContentDTO, icon: string, isSelected: boolean) => void;
  isSelected: boolean;
  icon: string;
};


export const UserBoardItem: React.FC<Props> = (props: Props) => {

  const onSelectBoard = () => {
    props.onSelect(props.sgBoard, props.icon, !props.isSelected)
  }

  return (
    <tr
    style={{cursor: "pointer"}}
    >
      <td onClick={() => onSelectBoard()} >
        <Form.Check
        className="ml-4"
        type="checkbox"
        value={props.sgBoard.id}
        id={props.sgBoard.id.toString()}
        checked={props.isSelected}
        />
      </td>
      <td
      className="card-category m-1 text-primary text-large col-10"
      onClick={() => onSelectBoard()}
      >
        {props.sgBoard.name}
      </td>
      <td
      className="card-category m-1 text-primary text-large col-10"
      >
        <a href={"https://dashboard.sgmarkets.com/my-boards/" + props.sgBoard.id} target="_blank" rel="noopener noreferrer">Open preview<i className="icon">open_in_new</i></a>
      </td>

      {/*<td>
        <Form.Control
        placeholder="Icon"
        aria-label="Icon - default value 'person'"
        value={props.icon}
        onChange={e => onChangeIcon(e.target.value)}
        />
      </td>*/}
    </tr>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserBoardItem);

