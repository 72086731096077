import { AppConfiguration } from "./configuration";
import { Environment } from "./environment";

export const getApiServiceUrl = (): string => AppConfiguration.apiUrl;

export const getApiServiceUrlAuth = (): string => AppConfiguration.apiUrlAuth;

export const getIcWidgetUrl = (env: Environment): string =>
  env === "production" ? "https://shared.sgmarkets.com/ic-widgets/v1/" : "https://shared-uat.sgmarkets.com/ic-widgets/v1/";

export const getIcApiUrl = (env: Environment): string =>
  env === "production" ? "https://ic-api.fr.world.socgen/" : "https://ic-api-hom.fr.world.socgen/";
