import { toPairs, isArray, fromPairs } from "lodash";
import * as queryString from "query-string";
import * as _ from "lodash";

export const stringifyLists = (object: Object) => {
  const attributes = toPairs(object).map(([key, value]) => (isArray(value) ? [key, value.join(",")] : [key, value]));
  return fromPairs(attributes);
};

export const stringifyParams = params => (params ? "?" + queryString.stringify(params) : "");

export const getUserNameFromMail = (email: string) => {
  let temp = email;
  temp = temp.replace("-ext", "");
  var split1 = temp.split("@");
  if(split1 && split1.length === 2) {
    let split2 = split1[0].split(".");
    if(split2.length === 2) {
      let firstName = _.startCase(split2[0]);
      let lastName = _.startCase(split2[1]);
      return firstName + " " + lastName;
    }
  }
  return email;
}

export const stringifyBoolean = (value?: boolean): string | undefined => {
  return value === true || value === false ? JSON.stringify(value) : undefined
}