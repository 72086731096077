/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import * as _Models from  "../../../datas/models"
import { Nav } from "react-bootstrap";

type Props = {
  isActive?: boolean;
  userBoard: _Models.UserBoardConfig;
  onSelectBoard: (board: _Models.UserBoardConfig) => void;
};

export const UserBoardTab: React.FC<Props> = (props: Props) => {

  return (
    <>
      <Nav.Item
        className={`nav-item`}
        role="button"
        key={props.userBoard.sgBoardId}
        onClick={() => props.onSelectBoard(props.userBoard)}
      >
          <a className={"nav-link " + (props.isActive ? "active" : "")}
          style={{textOverflow: "ellipsis"}}
          data-tooltip-id={"tooltip" + props.userBoard.sgBoardId}
          data-tooltip-content="Personal board"
          data-tooltip-place="left"

          data-toggle="tooltip"
          data-placement="right"
          title="Personal board"
          data-original-title="Personal board">
            <i className="icon icon-sm pr-2">{props.userBoard.icon || "dashboard_customize"}</i>
            {props.userBoard.name}
          </a>
      </Nav.Item>
    </>
  );
};
export default UserBoardTab;
