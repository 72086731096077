import * as React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { Store } from "redux";
import { History } from "history";
import { AppState } from "app/store/store.states";
import { ErrorHandler } from "common/components/ErrorHandler/ErrorHandler";
import AppRouter from "./App.router";

interface Props {
  store: Store<AppState>;
  history: History;
}

export const App: React.FC<Props> = ({ store, history }) => {
  
  return ( 
  <>
    <Provider store={store}>
      <ConnectedRouter history={history} >
          <ErrorHandler>
            <BrowserRouter>
                  <AppRouter />
            </BrowserRouter>
          </ErrorHandler>
      </ConnectedRouter>
    </Provider>
  </>);
};
