import * as React from "react";
import { RootState } from "app/store/store.states";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import homeSlice from "../store/home.slice";

const mapStateToProps = (state: RootState) => ({
  home: {...state.modules.home}
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onChangeSearchUserBoards: (searchTerm: string) => dispatch(homeSlice.actions.setSearchUserBoardValue(searchTerm)),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

export const UserBoardsSearch: React.FC<Props> = (props: Props) => {

  return (
    <div className="form-group m-0">
      <div className="input-group input-group-xl">
        <div className="input-group-prepend input-group-merged">
          <em className="icon icon-md text-secondary">search</em>
        </div>

        <input
          type="search"
          className="form-control form-control-xl"
          placeholder={"Search your board"}
          value={props.home.searchUserBoardsValue}
          onChange={e => props.onChangeSearchUserBoards(e.target.value)}
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserBoardsSearch);

