import { includes } from "lodash";
import { Store } from "redux";
import { RootState } from "app/store/store.states";
import { HttpResquestHeader } from "common/types/api";
import { AuthState } from "modules/Authentication/store/auth.states";

export const getConnectedUserEmail = (auth: AuthState): string | null =>
  auth && auth.user.email ? auth.user.email : null;

/**
 * this middleware is used in order to feed headers on http requests
 * we retrieve the SG connect token from the state
 * and we put it in the authorization header
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const apiMiddleware = (store: Store<RootState>) => next => action => {
  if (includes(action.type, "STARTED")) {
    const { auth } = store.getState();

    const userEmail = getConnectedUserEmail(auth);

    const headers: HttpResquestHeader = {
      authorization: auth && auth.token ? auth.token : null,
      userEmail,
    };
    action.meta = action.meta ? { ...action.meta, headers } : { headers };
  }
  next(action);
};
