/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import * as _Models from  "../../../datas/models"
import * as _Services from  "../../../datas/services"
import Logo from "common/components/Logo/Logo";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { RootState } from "app/store/store.states";
import HomeBoards from "./HomeBoards";
import datasSlice from "datas/store/datas.slice";
import UserBoardsModal from "./UserBoardsModal";
import _ from "lodash";

const mapStateToProps = (state: RootState) => ({
  app: {...state.app},
  auth: {...state.auth},
  home: {...state.modules.home}
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setDefaultBoards: (boards: _Models.DefaultBoardConfig[]) => dispatch(datasSlice.actions.updateAll({type: "defaultBoardConfigs", datas: boards})),
  setUserBoards: (boards: _Models.UserBoardConfig[]) => dispatch(datasSlice.actions.updateAll({type: "userBoardConfigs", datas: boards})),
  setTeamConfigs: (teamConfigs: _Models.TeamProfile[]) => dispatch(datasSlice.actions.updateAll({type: "teamConfigs", datas: teamConfigs})),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};
type State = {
  isLoading: boolean;
}

export const HomeScreen: React.FC<Props> = (props: Props) => {

  const [state, setState] = React.useState<State>({isLoading: true});

  React.useEffect(() => {
    if(props.auth.loggedIn && _.isEmpty(props.auth.token) === false) {
      _Services.getUserClosestTeamProfile(props.auth.user?.team ?? "")
      .then(teamProfile => {
        console.log("USER TEAMS CONFIGS : ");
        console.log(teamProfile);
        props.setTeamConfigs(teamProfile ? [teamProfile] : []); 
        return teamProfile ? [teamProfile] : [];
      })
      .then(teamProfiles =>
        Promise.all([
          _Services.getUserTeamDefaultBoards(teamProfiles.map(tp => tp.id)).then(datas => {props.setDefaultBoards(datas); return datas;}),
          _Services.getAllDatasets()
          .then(datasets => {
            if(datasets.some(d => d.id === _Services.getUserBoardConfigsId(props.auth.user))) {
              return _Services.getUserBoards(props.auth.user)
              .then(datas => {props.setUserBoards(datas); return datas;});
            }
            else {
              return _Services.createUserBoards(props.auth.user)
              .then(datas => {props.setUserBoards([]); return [];});
            }
          })
        ])
      )
      .then(datas => setState({isLoading: false}));
    }
  }, [props.auth.loggedIn, props.auth.token, props.auth.user?.team, props.auth.user?.email]);

  return (
    <div className="d-flex flex-column justify-content-end py-1" >
      <div className="mb-2">
        <div className="row m-0">
          <div className="ml-4 mt-2 mb-3">
            <Logo name={"Sales analytics 360"}/>
          </div>
        </div>
      </div>

      {state.isLoading
        ? null
        : (<>
          <UserBoardsModal />
          <HomeBoards />
        </>)
      }
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
