import * as React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { RootState } from "app/store/store.states";
import appSlice from "app/store/app.slice";
import BoardScreen from "modules/Board/components/BoardScreen";
import classNames from "classnames";
import { DefaultBoardConfig, UserBoardConfig } from "datas/models";
import DefaultBoardTab from "./DefaultBoardTab";
import UserBoardTab from "./UserBoardTab";
import { Nav } from "react-bootstrap";
import homeSlice from "../store/home.slice";
import { debounce } from "lodash";

const mapStateToProps = (state: RootState) => ({
  app: {...state.app},
  datas: {...state.datas},
  home: {...state.modules.home}
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setCurrentBoard: (board?: DefaultBoardConfig | UserBoardConfig) => dispatch(appSlice.actions.setCurrentBoard(board)),
  toogleUserBoardsModal: () => dispatch(homeSlice.actions.toogleUserBoardsModal())
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

export const HomeBoards: React.FC<Props> = (props: Props) => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dimensions, setDimensions] = React.useState({ mainMinHeight: window.innerHeight - 150 });
  const handleResize = () => setDimensions({ mainMinHeight: window.innerHeight - 150});

  React.useEffect(() => {
    const debouncedHandleResize = debounce(handleResize, 500);
    window.addEventListener("resize", debouncedHandleResize);
  });


  React.useEffect(() => {
  }, []);

  const getCurrentBoardId = (): string | undefined => {
    if(props.app.currentBoard) {
      return props.app.currentBoard?.sgBoardId;
    }
    else {
      return props.datas.defaultBoardConfigs.find(x => x)?.sgBoardId;
    }
  }

  return (
    <>
      <div className="d-flex">

        <div className="leftMenu leftMenuOpen">
          <Nav className={classNames("nav nav-vertical-pills nav-hover mb-5 leftMenu2")}>
            {props.datas.defaultBoardConfigs?.map((board) => (
              <DefaultBoardTab
                defaultBoard={board}
                onSelectBoard={props.setCurrentBoard}
                isActive={board?.sgBoardId === getCurrentBoardId()}
              />
            ))}

            {props.datas.userBoardConfigs?.map((board) => (
              <UserBoardTab
                userBoard={board}
                onSelectBoard={props.setCurrentBoard}
                isActive={board?.sgBoardId === getCurrentBoardId()}
              />
            ))}

            <Nav.Item className="d-flex flex-column align-items-center justify-content-center mt-3" style={{cursor: "pointer"}} onClick={props.toogleUserBoardsModal}>
              <button className="btn btn-lg sgbs-btn-default"><i className="icon icon-md mr-2">add</i> Add personal boards</button>
            </Nav.Item>
          </Nav>
        </div>

        <div className="container w-100 mw-100 m-0 p-0 border bg-lvl2">
          <div className="mw-100 overflow-scroll w-100 vh-100">
              {[...props.datas.defaultBoardConfigs, ...props.datas.userBoardConfigs].map((board) => (
                <BoardScreen currentBoard={board} />
              ))}
          </div>
        </div>
      </div>

    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(HomeBoards);
