import {
  AsyncActionTypes,
  LogLevels,
  LogMessage,
  LogTypes,
  AsyncActionLog,
  ActionMeta,
  ActionLog,
} from "modules/Authentication/types/logger.typings";
import { isEmpty, noop } from "lodash";
import { Logger, LogConfiguration } from "@dcc-cli/ic-logger/dist/types";
import { MonitoringLogger } from "@dcc-cli/ic-logger/dist";
import { AnyAction } from "redux";
import { Maybe } from "maybe-monade";

/**
 * This logger is a sample of implementation.
 * By default the logger is empty, you have to implement it !
 * It parses the current dispatched action, and he logs it with the correct log level
 * We use two main dependencies:
 *  maybe-moade (https://github.com/bouraine/maybe-monade#readme) for using monads
 *  ic-logger (https://sgithub.fr.world.socgen/ic/ic-web-libs) for implementing the logger (which logs in Kibana)
 */

const service = { log: () => undefined, forceSend: () => undefined, setHeaders: () => undefined };
const logConfiguration: LogConfiguration = { dryRun: false, service, showDebugInformation: true };
// we need to implement it !
const emptyLogger = new MonitoringLogger({ ...logConfiguration });

export const logAction = (action: AnyAction) => {
  createLogMessageFromAction(action).do(message => (!isEmpty(message) ? logMessage(message) : noop));
};

export const createLogMessageFromAction = ({ meta, type }: AnyAction): Maybe<LogMessage> => {
  if (!isActionCanBeLogged(meta, type)) {
    return Maybe.none();
  }
  const logger = meta.logger;
  return Maybe.some(
    getAsyncActionLogMessage(type, logger as AsyncActionLog).getOrElse(getActionLogMessage(type, logger as ActionLog))
  );
};

export const logError = (error: Error, stack: string) => {
  emptyLogger.technical("UnknownError", `${error.message}${stack}`, LogLevels.error);
};

export const logMessage = (message: LogMessage): void => {
  const logger: Logger = emptyLogger.setCustom("feature", message.feature).setCustom("event", message.event);
  switch (message.type) {
    case LogTypes.feature:
      logger.functional(message.name, message.description);
      break;
    case LogTypes.functional:
      logger.functional(message.name, message.description);
      break;
    case LogTypes.technical:
      logger.technical(message.name, message.description, message.level ? message.level : LogLevels.error);
      break;
    case LogTypes.performance:
      logger.performance(message.name, message.description, message.watchKeyOrDuration);
  }
};

const getAsyncActionLogMessage = (name: string, { event, description }: AsyncActionLog): Maybe<LogMessage> => {
  const message = { name, feature: name.split("/")[0], event, description };
  if (name.indexOf(AsyncActionTypes.started) >= 0 || name.indexOf(AsyncActionTypes.done) >= 0) {
    return Maybe.some<LogMessage>({ ...message, type: LogTypes.feature, level: LogLevels.info });
  } else if (name.indexOf(AsyncActionTypes.failed) >= 0) {
    return Maybe.some<LogMessage>({ ...message, type: LogTypes.technical, level: LogLevels.error });
  } else {
    return Maybe.none<LogMessage>();
  }
};

const getActionLogMessage = (name, { description, event, level, logType }: ActionLog): LogMessage => ({
  name,
  type: logType,
  feature: name.split("/")[0],
  event,
  description,
  level,
});

const isActionCanBeLogged = (meta: ActionMeta, actionName: string): boolean => {
  return !isEmpty(actionName) && actionName.split("/").length > 0 && !!meta && !!meta.logger;
};
