import * as React from "react";
import ErrorPage from "./ErrorPage";

const ServerErrorPage: React.FC = () => (
  <ErrorPage
    code={500}
    titleLabel="Something's wrong."
    descriptionLabel=" We're unable to complete your request at the moment."
    fullscreen={true}
  />
);

export default ServerErrorPage;
