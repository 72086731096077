import { RootState } from "app/store/store.states";
import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { History } from "history";
import { modulesReducers } from "./modules.reducer";
import { authReducer } from "modules/Authentication/store/auth.slice";
import appSlice from "./app.slice";
import datasSlice from "datas/store/datas.slice";

const appReducers = (history: History) => combineReducers<RootState>({
    app: appSlice.reducer,
    modules: modulesReducers,
    datas: datasSlice.reducer,
    router: connectRouter(history),
    auth: authReducer,
});

export const rootReducer = (history: History) => appReducers(history);

export default rootReducer;