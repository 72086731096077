import ENDPOINTS from "common/constants/endpoints.const";
import { createRepository } from "common/utils/api/api";
import { sgLambdaStreamToObject } from "common/utils/api/api.helper";
import DefaultBoardConfig from "datas/models/DefaultBoardConfig.m";
import _ from "lodash";

export const getAllDefaultBoards = (): Promise<DefaultBoardConfig[]> => {
  return createRepository()
  .getStreamFromAPI(ENDPOINTS.DefaultBoardConfigs)
  .then((response) => response.text())
  .then((response) => sgLambdaStreamToObject<DefaultBoardConfig>(response))
  .then((response) => _.orderBy(response, "position"));
};

export const getUserTeamDefaultBoards = (userTeamConfigIds: string[]): Promise<DefaultBoardConfig[]> => {
  return createRepository()
  .getStreamFromAPI(ENDPOINTS.DefaultBoardConfigs)
  .then((response) => response.text())
  .then((response) => sgLambdaStreamToObject<DefaultBoardConfig>(response))
  .then(datas => userTeamConfigIds.length === 0 
    ? [] 
    : datas.filter(d => userTeamConfigIds.some(id => d.teamProfileId === id)))
  .then((response) => _.orderBy(response, "position"));
};

export default {
  getUserTeamDefaultBoards,
  getAllDefaultBoards
};
