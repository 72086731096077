import { SgBoardContentDTO } from "datas/dtos";
import { UserBoardConfig } from "datas/models";

export const SGBoardToUserBoard = (sgBoard: SgBoardContentDTO, userEmail: string, userId: string, icon?: string): UserBoardConfig => {
    const userBoard: UserBoardConfig = {
        icon: icon || "",
        name: sgBoard.name,
        position: "0",
        sgBoardConfigId: "",
        sgBoardId: sgBoard.id.toString(),
        userEmail: userEmail,
        userId: userId
    }
    return userBoard;
}