import { connect } from "react-redux";
import { SgHeader, Props } from "common/components/Header/SgHeader";
import { getEnvironment } from "app/config/environment";
import { RootState } from "app/store/store.states";

interface OwnProps {}

const mapStateToProps = (state: RootState, ownProps: OwnProps): Props => ({
  environment: getEnvironment(),
  userLogin: state.auth.user.email,
  applicationId: "cpr-salesanalytics-ui",
  defaultRecipient: "list.par-gbsu-cld-cli-client-monitoring@sgcib.com",
  currentLanguage: "en",
});

export const HeaderContainer = connect<Props, any, OwnProps, RootState>(mapStateToProps)(SgHeader);
