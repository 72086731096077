import { getApiServiceUrl } from "app/config/getApiServiceUrl";
import ENDPOINTS from "common/constants/endpoints.const";
import { createRepository } from "common/utils/api/api";
import { ObjectToSgLambdaStream, sgLambdaStreamToObject } from "common/utils/api/api.helper";
import { getUserNameFromMail } from "common/utils/stringify";
import { User } from "datas/models";
import Dataset from "datas/models/Dataset.m";
import UserBoardConfig from "datas/models/UserBoardConfig.m";
import _ from "lodash";

export const getUserBoardConfigsId = (user: User) => ENDPOINTS.UserBoardConfigs + "_" + getUserNameFromMail(user.email).replace(" ", "_") + "_" + user.id;

export const getAllDatasets = (): Promise<Dataset[]> => {
  let url = getApiServiceUrl();
  url = url.substring(0, url.length - 1);
  
  return createRepository()
  .getStreamFromSG(url)
  .then((response) => {
    const t = response.text();
    return t;
  })
  .then((response) => 
  {
    //const t = sgLambdaStreamToObject<Dataset>(response);
    const t = JSON.parse(response);
    return t;
  });
};

export const getUserBoards = (user: User): Promise<UserBoardConfig[]> => {
  return createRepository()
  .getStreamFromAPI(getUserBoardConfigsId(user))
  .then((response) => response.text())
  .then((response) => sgLambdaStreamToObject<UserBoardConfig>(response))
  .then((userBoards) => userBoards.filter(b => b.userEmail === user.email && b.userId === user.id))
  .then((response) => _.orderBy(response, "position"));
};

export const createUserBoards = (user: User): Promise<Response> => {
  return createRepository()
  .postStreamFromAPI(
    getUserBoardConfigsId(user),
    undefined,
    {
    },
    {
      content: ""
    }
  )
};

export const replaceUserBoards = (userBoardConfig: UserBoardConfig[], user: User): Promise<any> => {
  return createRepository()
  .postStreamFromAPI(
    getUserBoardConfigsId(user),
    undefined,
    {
    },
    {
      content: ObjectToSgLambdaStream(userBoardConfig)
    }
  )
}
