import * as React from "react";
import {
  getWidgetBus,
  getSgwtAccountCenterElement,
  getSgwtConnectElement,
} from "modules/Authentication/utils/getWidgetBus";
import { Loading } from "common/components/Loading/Loading";
import { logMessage } from "modules/Authentication/utils/logger";
import { LogLevels, LogTypes } from "../types/logger.typings";
import { Dispatch } from "redux";
import { authActions } from "../store/auth.slice";
import UserInfoDTO from "datas/dtos/UserInfo.dto";
import { RootState } from "app/store/store.states";
import { connect } from "react-redux";

const mapStateToProps = (state: RootState) => ({
  ...state.auth
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setToken: (token: string) => dispatch(authActions.setToken(token)),
  logIn: (userEmail: string) => dispatch(authActions.logIn({email: userEmail})),
  logOut: () => dispatch(authActions.logOut()),
  setUserInfo: (userInfo?: UserInfoDTO) => dispatch(authActions.setUserInfo(userInfo))
});

export type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & React.PropsWithChildren<{}>;
export type State = {};

class AuthProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const bus = getWidgetBus();
    if (bus) {
      bus.subscribe("sg-connect.access-token", this.handleTokenChanged);
      bus.publish('sg-connect.granted-scope', "profile openid api.sg-dashboard-api.read api.sg-dashboard-api.load-external api.getcontactinformation.v1 mail api.manage-commercial-clients.v2 api.manage-contacts.v1 ");
      bus.subscribe("sg-connect.user-connection", this.handleUserConnectionChanged);
      bus.subscribe("sg-connect.user-info", this.handleUserInfoChanged);
    }

    const sgwtConnect = getSgwtConnectElement();
    if (sgwtConnect) {
      sgwtConnect.sgwtConnect.on("authorizationExpired", () => this.handleTokenExpired());
      sgwtConnect.sgwtConnect.on("renewAuthorizationError", () => this.handleOnRenewError());
    }
  }

  render() {

    if (!this.props.loggedIn) {
      return <Loading />;
    }

    return this.props.children;
  }

  handleTokenExpired = () => {
    logMessage({
      name: "signOut",
      feature: "Authentication",
      event: "app.sessionExpired",
      level: LogLevels.error,
      type: LogTypes.technical,
      description: "User redirected to endSession page after sgwtConnect <authorizationExpired> event is fired",
    });
    this.props.logOut();
    const sgwtAccountCenter = getSgwtAccountCenterElement() as any;
    sgwtAccountCenter.signOut();
  };

  handleOnRenewError = () => {
    logMessage({
      name: "renewTokenError",
      feature: "Authentication",
      event: "app.renewTokenError",
      level: LogLevels.error,
      type: LogTypes.technical,
      description: "sgwtConnect error while trying to renew session token",
    });
  };

  handleTokenChanged = (token?: string) => {
    if (token) {
      this.props.setToken(token);
    }
  };

  handleUserConnectionChanged = (userConnection: { mail?: string; connected?: boolean }) => {
    if (userConnection?.connected) {
      this.props.logIn(userConnection.mail);
    }
  };

  handleUserInfoChanged = (userInfo: UserInfoDTO | undefined) => {
    this.props.setUserInfo(userInfo);
  };
}

export default connect((state: RootState) => ({...state.auth}), mapDispatchToProps)(AuthProvider);