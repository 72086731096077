import { ROUTES } from "common/constants/routes.const";
import * as React from "react";
//import { NavItem } from "common/components/NavItem/NavItemHeader";
import { NavLink } from "react-router-dom";
import { Collapse, Nav, NavbarToggler, NavItem } from "reactstrap";

type Props = {
  onClick?: () => void;
}

type State = {
  collapsed: boolean;
}

export const HeaderMenu: React.FC<Props> = (props: Props) => {

  const [state, setState] = React.useState<State>({collapsed: true});

  const toggle = () => setState({collapsed: !state.collapsed})

  return (
    <>
    <NavbarToggler onClick={toggle} className="mr-auto">
      Menu
      <i className="icon icon-md text-secondary">arrow_drop_down</i>
    </NavbarToggler>

    <Collapse isOpen={!state.collapsed} navbar>
      <Nav navbar>
        <NavItem>
          <NavLink to={ROUTES.HOME} title="Home" onClick={props.onClick} className="nav-link" exact on>Home</NavLink>
        </NavItem>
      </Nav>
    </Collapse>
  </>
  );
};
